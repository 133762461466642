import React from "react";
import "./Services.scss";
import ScreenHeader from "../../globalcomponents/ScreenHeader/ScreenHeader.jsx"; 
import T24LeftImage from "../../components/T24LeftImage/T24LeftImage.jsx";  
import T24RightImage from "../../components/T24LeftImage/T24RightImage.jsx";

const Webdesign = () => {
  return (
    <div>
      <ScreenHeader title="Web Design & Development" subtitle="Web Design & Development" /> 
        <T24LeftImage
        bg={"bg-lightblue.svg"}
        img={"5.png"}
        title={"What we Offer!"}
        para={[
          "with tremendous growth in online marketing, it has become a necessity for every business to have a website to show case their presence in the market. Website development is a one-time investment, with huge returns in the current market. But most of the companies are in a critical dilemma – “Where to find the best company that makes our site function dynamically and effectively?",
          "At eHeys Technologies, we provide an end-to-end solution for all your web development needs. We pride in providing a quick-loading, professionally attractive and user-friendly web designs – ranging from custom-graphic design to e-commerce web design – with flexible, cost-effective and efficient services. Our cutting-edge designs are customized to suit your specific needs and allow you to transform your ideas into a well-designed reality."
        ]}
        />
        <T24RightImage
        bg={"bg-orange.svg"}
        img={"2.png"}
        title={"Technologies"}
        para={[
          "Simplest of the websites can be built with standards like HTML and CSS. Combine these with Javascript, and we get the most attractive websites that can also have interactive functionality. But as the size of website grows larger, and changes are frequent, we need to go for Content Management Systems (CMS).",
          "However we often cater to special needs of our customers, and handle other technologies as well. If you have a specific requirement dealing with any technology not mentioned above, please ask us. If we are confident that we can provide the same high quality service in your chosen technology, we may offer to handle it for you."
        ]}
      />
    </div>
  );
};
export default Webdesign;
