import React from "react";
import "./Aboutus.scss";
import ScreenHeader from "../../globalcomponents/ScreenHeader/ScreenHeader.jsx"; 
import AboutLeftImage from "../../components/AboutImage/AboutLeftImage";
import ServiceList from '../../components/ServiceList/ServiceList.jsx';

const Aboutus = () => {
  return (
    <div>
      <ScreenHeader title="About Us" subtitle="About us" />
        <AboutLeftImage />
        <ServiceList/>
    </div>
  );
};
export default Aboutus;
