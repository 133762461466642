import React from "react";
import "./Services.scss";
import ScreenHeader from "../../globalcomponents/ScreenHeader/ScreenHeader.jsx"; 
import T24LeftImage from "../../components/T24LeftImage/T24LeftImage.jsx";  
import T24RightImage from "../../components/T24LeftImage/T24RightImage.jsx";

const MobileApp = () => {
  return (
    <div>
      <ScreenHeader title="Mobile App Development" subtitle="Mobile App Development" /> 
        <T24LeftImage
        bg={"bg-lightblue.svg"}
        img={"5.png"}
        title={"What we Offer!"}
        para={[
          "Our comprehensive mobile app development services can cater to support your products ranging from web integration to a mobile portal. Our Mobile App team has expertise in developing any requirements to propel your business to the next level by providing pretty much everything your customers would need to see in your mobile app.",
          "At eHeys Technologies, we analyse and suggest on what approach should be taken for a specific requirement, whether to go for a native development or a cross-platform or hybrid approach. We also suggest on what platform to hit first, whether it would be iOS or Android or Windows or all of them, depending on the type of application and your target users. This helps our customers in getting to market quickly and budget their investments in areas that would benefit them most. We help by brainstorming on the right approach to going mobile required to see their app succeed and work together as a trusted partner."
        ]}
        />
        <T24RightImage
        bg={"bg-orange.svg"}
        img={"2.png"}
        title={"Process"}
        para={[
          "Consultation and Strategise: This would be the first step we take in trying to understand on what the customer has in mind and how we can help developing the idea. Though sometimes the market data can be a bit confusing during the research, eHeys Technologies can step in to make you understand the challenges, advantages and pitfalls for a particular requirement through our expertise.",
          "Design: Having to thrill your customers to make them use your mobile app would be the biggest challenge and design would be the one of the most important aspect. The limitations of using the app in a small screen can be overcome with a good design and eHeys Technologies can work with you on that.",
          "Development: After understanding the requirements clearly and after the initial planning is completed, the development work is started along with the design phase by our experienced developers and the ones that you can certainly depend on. We have expertise working in both native and cross-platform app development."
        ]}
      />
    </div>
  );
};
export default MobileApp;
