import React from "react";
import "./ContactForm.scss";
import { Form, Button, Row, Col } from "react-bootstrap";

const ContactForm = () => {
  return (
    <div className="ContactForm">
      <Form>
        <Row className="mb-4">
          <Form.Group as={Col} xs={12} md={6}>
            <Form.Label>Name:</Form.Label>
            <Form.Control type="text" placeholder="Name" />
          </Form.Group>
          <Form.Group as={Col} className="margintop" xs={12} md={6}>
            <Form.Label>Company:</Form.Label>
            <Form.Control type="text" placeholder="Company" />
          </Form.Group>
        </Row>
        <Row className="mb-4">
          <Form.Group as={Col} xs={12} md={6}>
            <Form.Label>Email:</Form.Label>
            <Form.Control type="email" placeholder="Email" />
          </Form.Group>
          <Form.Group as={Col} className="margintop" xs={12} md={6}>
            <Form.Label>Mobile:</Form.Label>
            <Form.Control type="text" placeholder="Mobile" />
          </Form.Group>
        </Row>

        <Form.Group className="mb-4 textarea">
          <Form.Label>Message</Form.Label>
          <Form.Control as="textarea" rows={6} />
        </Form.Group>
        <Button variant="primary" type="submit">
          Send 
        </Button>
      </Form>
    </div>
  );
};
export default ContactForm;
