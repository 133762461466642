import React from 'react';
import './KeyFeatureBox.scss'; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const KeyFeatureBox = (props) => {
    return (
        <div className="card fearture-card">
            <div className="card-body">
                <div className="icon " style={{backgroundColor:props.color}}>
                    <FontAwesomeIcon
                        className="edit-icon"
                        icon={props.icon}
                    />
                </div>
                <h5 className="mb-3 ">{props.title}</h5>
                <p className="mb-3 ">{props.para}</p>
            </div>
        </div>
    );
}

export default KeyFeatureBox;
