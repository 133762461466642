import React from "react";
import "./AboutImage.scss";
import { Col, Container, Row } from "react-bootstrap";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
const AboutLeftImage = (props) => {
  return (
    <div>
      <Container className="AboutLeftImage">
        <Row> 
          <Col xs={12} md={6}>
            <Fade right>
              <div className="content-sec">
                <h5 className="sub-title">ABOUT US</h5>
                <h2>A Few Words About Us</h2>
                <p>With an industrial experience of close to a decade, we have tapped into diverse spaces in the digital domain and made many brands rise to the mainstream beating competition at the global level. We embrace the latest techniques and technologies to deliver great satisfaction to our customers at each level of their requirement</p>

                <div class="features-text">
                  <h6>Our Mission</h6>
                  <p>Our goal is to differentiate clients from the me-too clutter in the networked digital space. Simply put, we optimize your dreams and demands and give it new dimensions and wings. So that you lead the pack in the avatars of digital marketing, branding and other solutions.</p>
                </div>
                {(props.para || []).map((data, index) => (
                  <p key={index}>{data}</p>
                ))}
              </div>
            </Fade>
          </Col>
          <Col xs={12} md={6}>
            <div className="image-section">
              <Zoom>
                <img
                  src={`asserts/about.png`} 
                  alt=""
                />
              </Zoom> 
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default AboutLeftImage;
