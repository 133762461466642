import React from "react";
import "./App.scss";
import Home from "./screens/Home/Home.jsx";
import Contact from "./screens/Contact/Contact.jsx";
import T24 from "./screens/T24/T24.jsx";
import Aboutus from "./screens/Aboutus/Aboutus.jsx";
import Webdesign from "./screens/Services/Webdesign.jsx";
import Ecommerce from "./screens/Services/Ecommerce.jsx";
import MobileApp from "./screens/Services/MobileApp.jsx"; 
import Products from "./screens/Products/Products.jsx"; 

import { HashRouter, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";

const App = () => {
  return (
    <HashRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contact"
          element={
            <ProtectedRoute>
              <Contact />
            </ProtectedRoute>
          }
        />
        <Route
          path="/T24"
          element={
            <ProtectedRoute>
              <T24 />
            </ProtectedRoute>
          }
        />
         <Route
          path="/about"
          element={
            <ProtectedRoute>
              <Aboutus />
            </ProtectedRoute>
          }
        />
        <Route
          path="/webdesign"
          element={
            <ProtectedRoute>
              <Webdesign />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ecommerce"
          element={
            <ProtectedRoute>
              <Ecommerce />
            </ProtectedRoute>
          }
        />
        <Route
          path="/mobileapp"
          element={
            <ProtectedRoute>
              <MobileApp />
            </ProtectedRoute>
          }
        />
        <Route
          path="/products"
          element={
            <ProtectedRoute>
              <Products />
            </ProtectedRoute>
          }
        />
      </Routes>
    </HashRouter>
  );
};
export default App;
