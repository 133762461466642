import React from "react";
import "./T24LeftImage.scss";
import { Col, Container, Row } from "react-bootstrap";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
const T24LeftImage = (props) => {
  return (
    <div>
      <Container className="T24LeftImage">
        <Row>
          <Col xs={12} md={7}>
            <div className="image-section">
              <Zoom>
                <img
                  src={`asserts/T24/${props.bg}`}
                  className="bgimage"
                  alt=""
                />
              </Zoom>
              <Fade delay={500}>
                <img
                  src={`asserts/T24/${props.img}`}
                  className="overlayimage"
                  alt=""
                />
              </Fade>
            </div>
          </Col>
          <Col xs={12} md={5}>
            <Fade right>
              <div className="content-sec">
                <h2 className="right-content">{props.title}</h2>
                {(props.para || []).map((data, index) => (
                  <p key={index}>{data}</p>
                ))}
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default T24LeftImage;
