import React from "react";
import "./SliderShapeComponent.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircle,
    faHeart,
    faStar,
} from "@fortawesome/free-regular-svg-icons";
const SliderShapeComponent = () => {
    return (
        <>
            <div className="background-shape">
                <div className="circle1"></div>
                <div className="circle2"></div>
                <div className="circle3"></div>
                <div className="circle4"></div>
            </div>
            <div className="background-animation">
                <div className="item1">
                    <FontAwesomeIcon
                        className="edit-icon"
                        icon={faHeart}
                    />
                </div>
                <div className="item2">
                    <FontAwesomeIcon
                        className="edit-icon"
                        icon={faStar}
                    />
                </div>
                <div className="item3">
                    <FontAwesomeIcon
                        className="edit-icon"
                        icon={faStar}
                    />
                </div>
                <div className="item4">
                    <FontAwesomeIcon
                        className="edit-icon"
                        icon={faCircle}
                    />
                </div>
                <div className="item5"></div>
            </div>
        </>
    );
};

export default SliderShapeComponent;
