import React from "react";
import "./Contact.scss";
import { Col, Container, Row } from "react-bootstrap";
import ScreenHeader from "../../globalcomponents/ScreenHeader/ScreenHeader.jsx";
import ContactLeft from "../../components/ContactLeft/ContactLeft.jsx";
import ContactForm from "../../components/ContactForm/ContactForm.jsx";
import Fade from "react-reveal/Fade";

const Contact = () => {
  return (
    <div>
      <ScreenHeader title="Contact Us" subtitle="Contact" />
      <Container className="Contact-page-wrapper">
        <Row>
          <Col xs={12} md={5}>
            <Fade left>
              <ContactLeft />
            </Fade>
          </Col>
          <Col xs={12} md={7}>
            <Fade right>
              <ContactForm />
            </Fade>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Contact;
