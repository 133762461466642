import React from "react";
import "./T24LeftImage.scss";
import { Col, Container, Row } from "react-bootstrap";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
const T24RightImage = (props) => {
  return (
    <div>
      <Container className="T24LeftImage">
        <Row>
          <Col xs={12} md={5}>
          <Fade left> 
            <div className="content-sec">
              <h2>{props.title}</h2>
              {(props.para || []).map((data, index) => (
                <p key={index}>{data}</p>
              ))}
            </div>
            </Fade>

          </Col>
          <Col xs={12} md={7}>
            <div className="image-section left-img-sec">
              <Zoom>
                <img
                  src={`asserts/T24/${props.bg}`}
                  className="bgimage"
                  alt=""
                />
              </Zoom>
              <Fade delay={500}>
                <img
                  src={`asserts/T24/${props.img}`}
                  className="overlayimage"
                  alt=""
                />
              </Fade>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default T24RightImage;
