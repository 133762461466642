import React from "react";
import "./FooterComp.scss";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faEnvelope,
  faPhoneVolume,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import FooterTopComp from "../FooterTopComp/FooterTopComp.jsx";
const FooterComp = () => {
  const linklist = [
    {
      title: "Software Solutions",
      link: "/webdesign",
    },
    {
      title: "Web Design & Development",
      link: "/webdesign",
    },
    {
      title: "Mobile App Development",
      link: "/mobileapp",
    },
    {
      title: "E-COMMERCE Development",
      link: "/ecommerce",
    },
    {
      title: "Graphics Design",
      link: "/webdesign",
    },
    {
      title: "Research and Development",
      link: "/webdesign",
    },
  ];
  const companyLinks = [
    {
      title: "About Us",
      link: "/about",
    },
    {
      title: "Privacy Policy",
      link: "/about",
    },
    {
      title: "Terms & Condition",
      link: "/about",
    },
    {
      title: "Careers",
      link: "/about",
    },
  ];
  return (
    <footer className="footer-section">
      <FooterTopComp />
      <Container>
        <Row className="footer-top">
          <Col xs={12} md={3}>
            <div className="footer-logo-sec">
              <img
                src="asserts/logo/white-text.svg"
                className="header-logo"
                alt="eHeys Technologies"
              />
              <p>
                At eHeys Technologies, we are not only committed to providing
                novel solutions but also to make our clients sustain and be
                among the elite in their domain.
              </p>
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className="footer-list Company-links">
              <h5 className="footer-title">Company Links</h5>
              <ul>
                {companyLinks.map((data, index) => (
                  <li key={index}>
                    <Link to={data.link}>
                      <FontAwesomeIcon
                        className="iconArrow"
                        icon={faArrowRight}
                      />
                      {data.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className="footer-list">
              <h5 className="footer-title">Quick Links</h5>
              <ul>
                {linklist.map((data, index) => (
                  <li key={index}>
                    <Link to={data.link}>
                      <FontAwesomeIcon
                        className="iconArrow"
                        icon={faArrowRight}
                      />
                      {data.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className="address-section-wrapper">
              <h5 className="footer-title">Get In Touch</h5>
              <ul className="address-section">
                <li>
                  <FontAwesomeIcon className="icon" icon={faLocationDot} />
                  <p>
                    No 38A, 1st Floor SKP Puram Greenwyas Road, Chennai - 600028
                  </p>
                </li>
                <li>
                  <FontAwesomeIcon className="icon" icon={faPhoneVolume} />
                  <p>+91-6381283848</p>
                </li>
                <li>
                  <FontAwesomeIcon className="icon" icon={faEnvelope} />
                  <p>info@eheystechnologis.com</p>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <div className="copyright-area ">
          <p>&copy; 2022 eHey Technologies. All rights reserved</p>
        </div>
      </Container>

      <img className="dots-shape" src="/asserts/dots.png" alt="Shape" />
      <img className="tringle-shape" src="/asserts/tringle.png" alt="Shape" />
      <img className="close-shape" src="/asserts/close.png" alt="Shape" />
      <img className="circle-shape" src="/asserts/circle.png" alt="Shape" />
    </footer>
  );
};

export default FooterComp;
