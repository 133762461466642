import React from "react";
import './Home.scss';
import ServiceList from '../../components/ServiceList/ServiceList.jsx';
import CarouselComponent from '../../components/CarouselComponent/CarouselComponent.jsx'; 
import KeyFeature from '../../components/KeyFeature/KeyFeature.jsx'; 

const Home = () => {
    return (
        <div> 
            <CarouselComponent />
            <KeyFeature />
            <ServiceList />  
        </div>
    );
};
export default Home; 