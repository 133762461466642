import React from "react";
import "./Products.scss";
import ScreenHeader from "../../globalcomponents/ScreenHeader/ScreenHeader.jsx";

const Products = () => {
  return (
    <div>
      <ScreenHeader title="Products" subtitle="Products" />
      <div className="construccion-img">
        <img
          src="/asserts/Page-construccion.png"
          alt="Page Under Construction"
        />
      </div>
    </div>
  );
};
export default Products;
