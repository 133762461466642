import React from "react";
import "./ServiceList.scss";
import ServiceComponent from "../../components/ServiceComponent/ServiceComponent.jsx";
import {
  faCruzeiroSign,
  faDiceD20,
  faBasketShopping,
  faMagnifyingGlassDollar,
  faAustralSign,
  faDiagramSuccessor,
} from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col } from "react-bootstrap";
import Headertag from "../../globalcomponents/Headertag/Headertag.jsx";
import Headertitle from "../../globalcomponents/Headertag/Headertitle.jsx";
import Fade from "react-reveal/Fade";
const ServiceList = () => {
  return (
    <div className="servicelist-wrapper">
      <div className="title-section">
        <Headertag title={"Services"} />
        <Headertitle title={"Provide Awesome Service To You"} />
      </div>
      <Container>
        <Row>
          <Col md={4}>
            <Fade bottom>
              <ServiceComponent
                title={"T24"}
                classval={"orange-bg"}
                para={
                  "The World's Most Functionally Rich Core Banking Product Used By Over 3000 financial institutions across the world."
                }
                icon={faAustralSign}
                link={"/T24"}
              />
            </Fade>
          </Col>
          <Col md={4}>
            <Fade left>
              <ServiceComponent
                title={"Web Design & Development"}
                para={
                  "We concentrate in the creation of high traffic Internet home pages with striking visuals, customized and optimized content, and adequate user interface details"
                }
                classval={"yellow-bg"}
                icon={faCruzeiroSign}
                link={"/webdesign"}
              />
            </Fade>
          </Col>
          <Col md={4}>
            <Fade top>
              <ServiceComponent
                title={"Mobile App Development"}
                para={
                  "Our comprehensive mobile app development services can cater to support your products ranging from web integration to a mobile portal."
                }
                classval={"red-bg"}
                icon={faDiceD20}
                link={"/mobileapp"}
              />
            </Fade>
          </Col>
          <Col md={4}>
            <Fade right>
              <ServiceComponent
                title={"E-COMMERCE Development"}
                para={
                  "We can make the whole process of e-commerce less complicated by sitting down with you and planning the different stages."
                }
                classval={"green-bg"}
                icon={faBasketShopping}
                link={"/ecommerce"}
              />
            </Fade>
          </Col>
          <Col md={4}>
            <Fade left>
              <ServiceComponent
                title={"Graphic Design"}
                classval={"blue-bg"}
                para={
                  "The expert team of Graphic designers, is skillful and invests time in researching current market trends and patterns in customer’s area of business activity"
                }
                icon={faMagnifyingGlassDollar}
                link={"/webdesign"}
              />
            </Fade>
          </Col>

          <Col md={4}>
            <Fade right>
              <ServiceComponent
                title={"Research and Development"}
                classval={"purple-bg"}
                para={
                  "We invest in a range of emerging technologies and breakthroughs, which we believe will create significant opportunities to deliver value to our customers."
                }
                icon={faDiagramSuccessor}
                link={"/webdesign"}
              />
            </Fade>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default ServiceList;
