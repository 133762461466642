import React from "react";
import "./ScreenHeader.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight
} from "@fortawesome/free-solid-svg-icons"; 
const ScreenHeader = (props) => {
  return (
    <div className="ScreenHeader">
      <h2>{props.title}</h2>
      <ol>
        <li>
          <Link to={"/"}>Home</Link>
        </li>
        <li>
          <FontAwesomeIcon className="icon" icon={faAngleRight} />
          {props.subtitle}
        </li>
      </ol>
    </div>
  );
};
export default ScreenHeader;
