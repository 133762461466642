import React from "react";
import "./T24.scss";
import ScreenHeader from "../../globalcomponents/ScreenHeader/ScreenHeader.jsx";
import T24LeftImage from "../../components/T24LeftImage/T24LeftImage.jsx";
import T24RightImage from "../../components/T24LeftImage/T24RightImage.jsx";
const T24 = () => {
  return (
    <div>
      <ScreenHeader title="T24" subtitle="T24" />
      <T24LeftImage
        bg={"bg-lightblue.svg"}
        img={"1.png"}
        title={"Customization service"}
        para={[
          "eheys Technologies does Our end-to-end customization adopt mature practices from our collective domain and technical expertise. Our deep Temenos products knowledge enables our Clients to adopt the product’s inherent processes without compromising on their requirements.",
        ]}
      />
      <T24RightImage
        bg={"bg-orange.svg"}
        img={"2.png"}
        title={"Upgrade and Technology Migration services"}
        para={[
          "Applications require frequent upgrades to help banks offer new functionality, address regulatory needs, meet market demands, keep up to date with technological trends, reduce costs and improve security. Regular Temenos application upgrades help addressing present and future needs of the banks and their customers.",
          "Our certified upgrade consultants are well qualified and experienced to address your upgrade engagements. Our services cover release upgrades (moving from a lower to a higher Temenos product release), module upgrades (technical upgrades plus implementation of new modules in latest releases), and technology migrations (desktop to browser / UXP, TAFC to TAFJ, jBase to a market leading RDBMs).",
        ]}
      />
      <T24LeftImage
        bg={"bg-green.svg"}
        img={"5.png"}
        title={"Migration services"}
        para={[
          "With data increasingly distributed in various applications, and information security regulations becoming even more rigorous, data migration becomes a challenge for any core banking transformation project. Experience, a sound methodology and strict governance can ensure success.",
          "Our migration services are led by a techno-functional team staffed by Business Analysts having strong domain expertise and migration experts skilled and experienced in migration projects. Our team follows a sound migration methodology with clearly defined phases, activities and deliverables. We can undertake an end-to-end data migration responsibility either as a part of our Implementation Services or as a separate engagement.  ",
        ]}
      />
      <T24RightImage
        bg={"bg-blue.svg"}
        img={"4.png"}
        title={"Customisation (L3) services"}
        para={[
          "Temenos offers productised solutions but at the same time allows its clients to customise their offering to meet their end customers needs. Quality of customisation is of the utmost importance to ensure targets are met, functionality is available to the end customers and customisations maintainability is ensured.",
          "eHey Technologies offshore data center (ODC) based in Bangalore, offers superior quality of customisations services to its clients. eHeys Technologies ODC is an approved Temenos development center, undertaking L3 customisations both as Temenos subcontractor and directly with its clients. Our customer testimonials offer the required assurance to our clients about our world class delivery.",
        ]}
      />
      <T24LeftImage
        bg={"bg-red.svg"}
        img={"8.png"}
        title={"Training & Documentation services"}
        para={[
          "Business readiness is a crucial phase for major projects. End-users need to be adequately trained to use the applications effectively. Comprehensive training programs result in reduced mistakes, workforce confidence and a better working environment. In addition to training, end-user readiness requires the preparation of standard operating procedures, user guides and other documentation the bank internal policies might mandate.",
          "We provide customised training programs to end-users to help them understand the features and functionality of Temenos products so as to use the application in the right context. Our Lead Business Consultants are skilled in articulating and creating operating procedures and user guides on digital learning platforms. ",
        ]}
      />

      <T24RightImage
        bg={"bg-lightblue.svg"}
        img={"9.png"}
        title={"QA & Testing services"}
        para={[
          "We are in the age of ‘agile’ where various phases are executed in parallel and changes are dynamic. There is a high probability of missed or misinterpreted requirements even in the best of processes. It is in the QA & Testing phase that client requirements are consolidated and validated. Testing is highly critical for the success of overall implementations.",
          "In eHey Technologies, our QA & Testing services are led by a techno-functional team staffed by Business Analysts having strong domain expertise and QA Analysts skilled in Test automation & Test management. Our test automation services are value-add collateral for our clients to meet project timelines, mitigating project risks without comprising on quality.",
        ]}
      />

      <T24LeftImage
        bg={"bg-orange.svg"}
        img={"7.png"}
        title={"Staff Augmentation services"}
        para={[
          "We offer flexible resourcing and engagement models to suit your needs. Our resources come with rich domain expertise having worked in the banking industry and transitioning to Temenos product implementations. Consulting and Business analysis services are led by our Lead Business consultants. To complement our business competency, we have Senior Technical resources with significant years of experience in various Temenos applications and other latest technologies.",
          "Various models are offered to suit your needs. Onsite, Offshore and Hybrid models have successfully been implemented in different occasions to address client requirements. Ring Fence Teams (RFT) approach can also augment bank’s capacity ensuring that IT departments can deliver according to their business expectations. RFT comprises of business, technical and QA/testing resources to address the full customizations delivery cycle.",
        ]}
      />
    </div>
  );
};
export default T24;
