import React from "react";
import "./SliderComponent.scss";
import SliderShapeComponent from "./SliderShapeComponent.jsx";
import { Container, Row, Col } from "react-bootstrap";
import Fade from "react-reveal/Fade";
const SliderComponent = (props) => {
  return (
    <div className="slider-sec bg-gradient">
      <SliderShapeComponent />
      <Container>
        <Row>
          <Col xs={12} md={6}>
            <div className="slider-content">
              <Fade>
                <ul className="mb-0 ps-1 d-flex flex-wrap align-items-center">
                  {(props.list || []).map((data, index) => (
                    <li key={index}>{data}</li>
                  ))}
                </ul>
              </Fade>
              <h2 className={props.classValues}>
                <Fade>{props.title}</Fade>
              </h2>
              <Fade bottom>
                <p className="mb-4">{props.para}</p>
              </Fade>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="slider-img">
              <Fade bottom>
                <img src={props.img} alt="eheys Technologies" />
              </Fade>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SliderComponent;
