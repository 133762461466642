import React from "react";
import MenuBar from "./components/MenuBar/MenuBar.jsx";
import FooterComp from "./components/FooterComp/FooterComp.jsx";
import ScrollTop from "./components/ScrollTop/ScrollTop.jsx";

const ProtectedRoute = ({ children }) => {
  return (
    <>
      <div className="main-section">
        <MenuBar />
        <div>{children}</div>
        <FooterComp />
        <ScrollTop />
      </div>
    </>
  );
};
export default ProtectedRoute;
