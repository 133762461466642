import React from "react";
import "./CarouselComponent.scss";
import SliderComponent from "../../components/SliderComponent/SliderComponent.jsx";
import { Carousel } from "react-bootstrap";
const CarouselComponent = () => {
  return (
    <Carousel className="slider-carousel-section">
      <Carousel.Item>
        <SliderComponent
          title={
            "The World's Most Functionally Rich Core Banking Product Used By Over 3000 financial institutions across the world."
          }
          list={["Idea", "Development", "Branding"]}
          para={
            "eHeys Technologies helps Banks and Financial institutions to transform their IT Aspects with Temenos products."
          }
          img={"/asserts/sliderimage.png"}
          classValues={"first-slide"}
        />
      </Carousel.Item>
      <Carousel.Item>
        <SliderComponent
          title={"We're Building Software For You"}
          list={["WHY CHOOSE US"]}
          para={
            "eHeys Technologies are now the most sought-after software factory in the world."
          }
          img={"/asserts/slide1.png"}
        />
      </Carousel.Item>
    </Carousel>
  );
};

export default CarouselComponent;
