import React from "react";
import "./MenuBar.scss";
import { Container, Navbar, NavDropdown, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
const MenuBar = () => {
  return (
    <Navbar className="header-menu-wrapper" sticky="top" expand="lg">
      <Container>
        <Navbar.Brand href="#">
          <div>
            <img
              src="asserts/logo/logo.jpeg"
              className="header-logo"
              alt="eHeys Technologies"
            />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" className="justify-content-end">
          <Nav className="my-2 my-lg-0 header-menu">
            <Link to={"/"}>Home</Link>
            <Link to={"/about"}>About Us</Link>
            <Link to={"/T24"}>T24?</Link>
            <NavDropdown title="Services" className="submenu_box">
              <Link to={"/T24"} className="dropdown-item">
                T24
              </Link>
              <Link to={"/webdesign"} className="dropdown-item">
                Web Design & Development{" "}
              </Link>
              <Link to={"/mobileapp"} className="dropdown-item">
                Mobile App Development{" "}
              </Link>
              <Link to={"/ecommerce"} className="dropdown-item">
                E-COMMERCE Development
              </Link>
            </NavDropdown>
            <Link to={"/products"}>Products</Link>
            <Link to={"/contact"}>Reach Us</Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MenuBar;
