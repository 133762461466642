import React from "react";
import "./ServiceComponent.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
const ServiceComponent = (props) => {
  const navigate = useNavigate();
  return (
    <div
      className="ServiceBox"
      onClick={() => navigate(props.link, { replace: true })}
    >
      <div className="ServiceBox-wraper">
        <div className={`ServiceBox-bx ${props.classval}`}>
          <FontAwesomeIcon className="icon" icon={props.icon} />
        </div>
        <div className="icon-content">
          <h4 className="title">{props.title}</h4>
          <p className="para">{props.para}</p>
        </div>
      </div>
    </div>
  );
};

export default ServiceComponent;
