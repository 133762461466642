import React from "react";
import "./ContactLeft.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faHeadphonesSimple,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
const ContactLeft = () => {
  return (
    <div className="ContactLeft">
      <h1>
        Let's talk about <br /> all things!
      </h1>
      <p>
        Write to us or give us a call. We will reply to you as soon as possible. 
        But yes, it can take up to 24 hours.
      </p>
      <div>
        <div className="list-section">
          <div className="icon-sec">
            <span><FontAwesomeIcon className="icon" icon={faLocationDot} /></span>
            
          </div>
          <p>No 38A, 1st Floor SKP Puram,<br/> Greenwyas Road, Chennai - 600028</p>
        </div>
        <div className="list-section blue">
          <div className="icon-sec">
            <span><FontAwesomeIcon className="icon" icon={faEnvelope} /></span>
            
          </div>
          <p>info@eheystechnologis</p>
        </div>
        <div className="list-section green">
          <div className="icon-sec">
            <span>   <FontAwesomeIcon className="icon" icon={faHeadphonesSimple} /></span>
         
          </div>
          <p>+91-6381283848</p>
        </div>
      </div>
    </div>
  );
};
export default ContactLeft;
