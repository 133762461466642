import React from "react";
import "./KeyFeature.scss";
import Fade from "react-reveal/Fade";
import { Container, Row, Col } from "react-bootstrap";
import {
  faClockRotateLeft,
  faFileInvoiceDollar,
  faCircleNodes,
  faCompassDrafting,
} from "@fortawesome/free-solid-svg-icons";
import KeyFeatureBox from "../KeyFeatureBox/KeyFeatureBox.jsx";
const KeyFeature = () => {
  return (
    <div className="KeyFeature-Sec">
      <Container>
        <Row>
          <Col xs={12} md={7}>
            <Row>
              <Col xs={12} md={6}>
                <Fade left>
                  <KeyFeatureBox
                    title="End to End Involvement"
                    para="eHeys Technologies can undertake end-to-end responsibility, from projects inception until rollout and post implementation support."
                    icon={faClockRotateLeft}
                    color="$dark-blue"
                  />
                </Fade>
              </Col>
              <Col xs={12} md={6} className="mt-5">
                <Fade left>
                  <KeyFeatureBox
                    title="Proven Methodologies"
                    para="Agile, aligning capabilities and services offered according to increased market needs, newly introduced products and regulatory changes ensures quality of delivery."
                    icon={faCircleNodes}
                    color="rgb(25, 135, 84)"
                  />
                </Fade>
              </Col>
              <Col xs={12} md={6} className="mobile-mt">
                <Fade left>
                  <KeyFeatureBox
                    title="Temenos Payment Hub"
                    para="eHeys Technologies has an established  Payments Competency Center for TPH implementations."
                    icon={faFileInvoiceDollar}
                    color="rgb(13, 202, 240)"
                  />
                </Fade>
              </Col>
              <Col xs={12} md={6} className="mt-5">
                <Fade left>
                  <KeyFeatureBox
                    title="Partners &amp; Cooperations"
                    para="eHeys Technologies is a Services &amp; Development Institution. We also cooperate with other Temenos Global Partners to deliver the needed solution."
                    icon={faCompassDrafting}
                    color="rgb(255 193 7)"
                  />
                </Fade>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={5} className="KeyFeature-heading-wrapper">
            <div className="KeyFeature-heading mb-0 ms-xl-5">
              <Fade right>
                <h6>Our key features</h6>
                <h2>
                  Our aim is to deliver excellence in service and
                  implementation.
                </h2>
                <p>
                  Whether onshore, offshore or hybrid engagement models are
                  followed, our project based strategy and proven methodologies
                  ensure project success.
                </p>
                <div className="about-btn-group">
                  <button className="btn eheys-btn-style mt-3">
                    Discover More
                  </button>
                </div>
              </Fade>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default KeyFeature;
