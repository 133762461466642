import React from "react";
import "./Services.scss";
import ScreenHeader from "../../globalcomponents/ScreenHeader/ScreenHeader.jsx"; 
import T24LeftImage from "../../components/T24LeftImage/T24LeftImage.jsx";  
import T24RightImage from "../../components/T24LeftImage/T24RightImage.jsx";

const Ecommerce = () => {
  return (
    <div>
      <ScreenHeader title="E-COMMERCE Development" subtitle="E-COMMERCE Development" /> 
        <T24LeftImage
        bg={"bg-lightblue.svg"}
        img={"5.png"}
        title={"What we Offer!"}
        para={[
          "Shopping online is growing rapidly in India and across the world. Many emerging entrepreneurs have started and running e-commerce shops successfully.",
          "There are also several solutions for budding e-commerce vendors. Some of these help you get started with selling online within a matter of hours, while others might require months of development. Technological requirements for selling online, hence, is highly dependent of the type of business and the target audience. A solution that suits well for a multi-category e-commerce portal with a big product catalogue will be overkill for a small site with handful of products.",
          "We have built several innovative online business platforms for our customers, with unique selling models. We have built site for manufacturers to take their wares online, publishers to sell across the world in multiple currencies, and for vendors with creative business models that mix in-site sales with external affiliates within a single platform."
        ]}
        />
        <T24RightImage
        bg={"bg-orange.svg"}
        img={"2.png"}
        title={"Process"}
        para={[
          "Selling online involves exchange of money online through banks and payment gateways. We have integrated a wide variety of payment gateways for our customers, and are conversant with the nitty-gritties of dealing with several payment gateway vendors and support teams.",
          "We can make the whole process of e-commerce less complicated by sitting down with you and planning the different stages. We would learn about your business model, and then apply what we have learnt through the years to your specific needs. We can also advise on the best payment gateway solution for your particular requirements."
        ]}
      />
    </div>
  );
};
export default Ecommerce;
