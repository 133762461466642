import React from "react";
import "./FooterTopComp.scss";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight
} from "@fortawesome/free-solid-svg-icons";
const FooterTopComp = () => {
  return (
    <Container>
      <Row>
        <Col>
          <div className="call-to-action ">
            <div className="text-section">
              <h2>
                Searching for an professional service for your software
                solutions?
              </h2> 
              <Link to='/'> 
                Get Started   <FontAwesomeIcon className="icon" icon={faArrowRight} />

              </Link>
            </div>
            <img
              className="white-circle"
              src="/asserts/circle.png"
              alt="White Circle"
            />
            <img
              className="white-dots"
              src="/asserts/white-dots.png"
              alt="shape"
            />
            <img
              className="white-dots-circle"
              src="/asserts/white-dots-circle.png"
              alt="shape"
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default FooterTopComp;
